import i18n from "@/libs/i18n";

export default [
  {
    title: i18n.t("Navbar.Home"),
    route: "home",
    customIcon: "Home-apps.svg",
    per: "All",
    per2: "All",
  },
  {
    title: i18n.t("Profile"),
    route: "Vendor-Show",
    customIcon: "Vendor.svg",
    per: "All",
    per2: "All",
  },
  {
    title: i18n.t("Navbar.Buyer_Guide"),
    customIcon: "BuyerGuide.svg",
    per: "All",
    per2: "All",
    children: [
      {
        title: i18n.t("Navbar.Guides_Index"),
        route: "PhoneGuide",
        icon: "AlignJustifyIcon",
        per: "All",
        per2: "All",
      },
      // {
      //     title: i18n.t('Navbar.Guides_Card_View'),
      //     route: 'PhoneGuideEcommerce',
      //     icon: "AlignJustifyIcon",
      //     per: "All",
      // },
    ],
  },
  {
    title: i18n.t("Navbar.Products"),
    customIcon: "Products.svg",
    per: "All",
    per2: "All",
    children: [
      {
        title: i18n.t("Navbar.Products_Index"),
        route: "ProductIndex",
        icon: "AlignJustifyIcon",
        per: "All",
        per2: "All",
      },
         
      // {
      //   title: i18n.t("Navbar.Add_New_Product"),
      //   route: "AddNewProduct-type",
      //   icon: "PlusCircleIcon",
      //   per: "All",
      //   per2: "new_product",
      // },
      // {
      //   title: i18n.t("Navbar.Add_Used_Product"),
      //   route: "AddProduct-used-type",
      //   icon: "PlusCircleIcon",
      //   per: "All",
      //   per2: "used_product",
      // },

      {
        title: i18n.t("Navbar.Add_Product"),
        route: "AddProduct-type",
        icon: "PlusCircleIcon",
        per: "All",
        per2: "All",
      },
      // {
      //   title: i18n.t("Navbar.New_Products"),
      //   icon: "AlignJustifyIcon",
      //   per: "All",
      //   per2: "new_product",
      //   children: [
          
      //     {
      //       title: i18n.t("Navbar.Add_New_Product"),
      //       route: "AddNewProduct-type",
      //       icon: "PlusCircleIcon",
      //       per: "All",
      //       per2: "new_product",
      //     },
      //     // {
      //     //     title: i18n.t('Navbar.Products_Card_View'),
      //     //     route: 'NewProductCardIndex',
      //     //     icon: 'AlignJustifyIcon',
      //     //     per: "All",
      //     // },
      //   ],
      // },
      // {
      //   title: i18n.t("Navbar.Used_Product"),
      //   icon: "SmartphoneIcon",
      //   per: "All",
      //   per2: "used_product",
      //   children: [
      //     {
      //       title: i18n.t("Navbar.Add_New_Product"),
      //       route: "AddProduct-used-type",
      //       icon: "PlusCircleIcon",
      //       per: "All",
      //       per2: "used_product",
      //     },
      //     // {
      //     //     title: i18n.t('Navbar.Products_Card_View'),
      //     //     route: 'ProductCardIndex',
      //     //     icon: 'AlignJustifyIcon',
      //     //     per: "All",
      //     // },
      //   ],
      // },
      {
        title: i18n.t("Navbar.Top_Products"),
        route: "TopProducts",
        customIcon: "Home-apps.svg",
        per: "All",
        per2: "All",
      },
    ],
    
  },
  {
    title: i18n.t("Navbar.Orders"),
    route: "OrderIndex",
    customIcon: "Orders.svg",
    per: "All",
    per2: "All",
  },
  {
    title: "المرتجعات",
    customIcon: "Orders.svg",
    route: "Refund-Index",
    per: "All",
    per2: "All",
  },
  {
    title: i18n.t("Navbar.Vendors_Transactions"),
    route: "Transactions-Index",
    customIcon: "AccountReports.svg",
    per: "All",
    per2: "All",
  },
  {
    title: i18n.t("Navbar.ٌReports"),
    route: "Diagnostic-Reports",
    customIcon: "DT-Icon.svg",
    per: "All",
    per2: "All",
  },
  {
    title: i18n.t("Navbar.Offers"),
    route: "Offers",
    customIcon: "Bundle.svg",
    per: "All",
    per2: "All",
  },
];
