<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">

     <b-nav-item-dropdown
     v-if="$store.state.notifications.unreadnotificationsNav != null &&  $store.state.notifications.unreadnotificationsNav.length >= 0"
      class="dropdown-notification mr-25 "
      :class="{ rotate: doesRotate }"
      menu-class="dropdown-menu-media"
      right>

      <template #button-content>
        <feather-icon
          :badge="$store.state.notifications.unreadnotificationsNav.length"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            التنبيهات
          </h4>
          <b-badge
            pill
            variant="light-primary"
          >
          جديد {{$store.state.notifications.unreadnotificationsNav.length}} 
          </b-badge>
        </div>
      </li>
    
      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >

        <!-- Account Notification -->
        <b-link
          v-for="(notification ,index) in $store.state.notifications.notificationsNav"
          :key="notification.id"
          @click.prevent="makeReadNav(notification.id,notification.dir_vednor_show, index)"
          :to="{
                path: notification.data.dir_vednor_show ? notification.data.dir_vednor_show : notification.dir_vednor_show ,
              }"
          
        >
            <b-media>
              <template #aside>
                <span  class="badge badge-dot" v-if="notification.read_at == null"></span>
                <b-avatar
                  size="32"
                  text="NO"
                  variant="light-danger"
                >
                <feather-icon icon="UserIcon"  />
              </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                   {{ notification.data.data}}
                </span>
              </p>
              <small class="notification-text font-9"  v-if="notification.created_at">{{  notification.created_at | formatDate }}  </small>
              <b-badge pill variant="light-warning" v-else> الان </b-badge>
            </b-media>

        </b-link>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        
        
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        role="link"
        @click="GoPage()"
      >
      روئية جميع التنبيهات</b-button>

      </li>
    </b-nav-item-dropdown>

      
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content v-if="userData != null">
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" >
              {{ userData.storename }}
            </p>
            <span class="user-status">{{ userData.adminstration }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.profile_picture"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ path: '/vendor/show/' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("Navbar.Profile") }} </span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>-->

        <b-dropdown-divider />

        <b-dropdown-item @click="Logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          {{ $t("Navbar.Logout") }}
          <!-- <b-button type="submit" @click="Logout">Logout</b-button> -->
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import eventBus from "@/libs/bus";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Pusher from 'pusher-js' // import Pusher
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    // Navbar Components
    DarkToggler,
    VuePerfectScrollbar
  },
  data() {
    return {
      userData: null,
      errors_back: "",
      showDismissibleAlert: false,
      notifications: {},
      doesRotate: false,
      pusher_key : process.env.VUE_APP_PUSHER_KEY,
      site_url_notify:  process.env.VUE_APP_SITE_URL
    };
  },

  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 30,
      wheelPropagation: true,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  created() {
    if(localStorage.getItem("userData")  != null){
        const decryptedText = this.CryptoJS.AES.decrypt(
        localStorage.getItem("userData"),
        "EncryptDataMobileMasr"
        ).toString(this.CryptoJS.enc.Utf8);
  
    this.userData = JSON.parse(decryptedText);
  }
    this.getnotification();
    this.subscribe();
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    Logout() {
      this.$store.dispatch("logout");
    },


    async getnotification(){
      await this.$store.dispatch('notifications/getnotificationsNav');
    },

    subscribe () {
      if(this.userData)
      {
      let pusher = new Pusher(this.pusher_key, { cluster: 'eu' })
      pusher.subscribe('notification.vendor.'+ this.userData.id )
      pusher.bind('App\\Events\\VendorNotify', data => {
          data['read_at'] = null;

          this.$bvToast.toast(
            data.data.data ,
            {
              title: 'تنبية جديد',
              solid: true,
              variant: 'info',
              toaster: 'b-toaster-bottom-left',
            }
          )


                const options = {
                    body:  data.data.data ,
                    action: this.site_url_notify + data.dir_show ,
                    icon: require('@/assets/images/logo/06.png'),
                  };
              navigator.serviceWorker.register('/service-worker.js');
              Notification.requestPermission(function(result) {
                if (result == 'granted') {
                  navigator.serviceWorker.ready.then(function(registration) {
                    registration.showNotification('MobileMasr', options);
                  });
                }
              });
                //end browser notification 




           //start push Data
           if(this.$store.state.notifications.notifications.data != undefined){
                this.$store.state.notifications.notifications.data.unshift(data);
              }
              this.$store.state.notifications.unreadnotifications.unshift(data);
              this.$store.state.notifications.notificationsNav.unshift(data);
              this.$store.state.notifications.unreadnotificationsNav.unshift(data);
            //End push Data



        this.warnDisabled();
      })
    }
    },

    async makeReadNav(notification_id,dir_vednor_show,index){
      if(notification_id != null){
        await this.$store.dispatch('notifications/makeReadNav',{notification_id, dir_vednor_show, index});
      }else{
        this.$store.state.notifications.notificationsNav[index].read_at = 1;
      }


      
    },
    
    warnDisabled(){
      this.doesRotate = true;
      setTimeout(() => {
        this.doesRotate = false;
      }, 1500)
    },

    GoPage(){
      this.$router.push('/notifications')
    },


  },
};
</script>

<style>
/* .rotate {
    rotate: 360deg;
    transition: rotate 5s;
  } */


  .rotate {
    animation: move .5s alternate 4 ease-in-out;
  }
  @keyframes move {
    10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
  }



  .dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {

    background-color: #7367f0;
}


.badge.badge-dot {
  position: absolute;
    right: 20px;
  background-color: #7367f0;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    vertical-align: middle;
}

.font-9{
    font-size: 9px !important;
}
  
  
</style>